<template>
  <div class="p-2 d-flex justify-content-between flex-wrap">
    <div class="d-flex align-items-center my-3">
      <v-select
        v-model="pageLength"
        :options="perPageOptions"
        :clearable="false"
        style="width: 200px"
        class="invoice-filter-select"
        @input="(value) => $emit('handlerPageSizeChange', value)"
      />
    </div>
    <div>
      <b-pagination
        :value="1"
        :total-rows="count"
        :per-page="filter.pageSize"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="my-3"
        @input="(value) => $emit('handlerPageChange', value)"
      >
        <template #prev-text>
          <i class="fad fa-chevron-left" />
        </template>
        <template #next-text>
          <i class="fad fa-chevron-right" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, toRefs } from '@vue/composition-api';

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    count: [Number],
    siparisler: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { filter } = toRefs(props);
    expo.pageLength = ref(filter.value.pageSize);
    expo.perPageOptions = ref([2, 10, 20, 40, 60, 80, 100]);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
