<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="siparisler"
      :line-numbers="false"
      :pagination-options="{
        enabled: false,
        perPage: 100,
        mode: 'pages',
      }"
      @on-selected-rows-change="selectionChanged"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionText: 'Satır Secildi.',
        clearSelectionText: 'Temizle',
      }"
      :search-options="{ enabled: false }"
      styleClass="vgt-table striped bordered"
    >
      <div slot="selected-row-actions">
        <b-button squared variant="danger" v-b-tooltip.hover="'Toplu Sil'" @click="handlerTopluSil">
          <i class="fad fa-trash" />
        </b-button>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'k_no'">
          <router-link
            :to="{
              name: 'siparis-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="font-weight-bold d-block">
              {{ props.row.k_no }}
            </span>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'tarih'">
          <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
            {{ props.row.tarih | momentFull }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'uye'" class="d-flex flex-column">
          <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
            {{ props.row.uye_ad }} {{ props.row.uye_soyad }}
          </b-badge>
          <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
            {{ props.row.uye_e_mail }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'odeme_tipi'">
          <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 14px">
            {{
              props.row.odeme_yontemi == 'kapida_odeme'
                ? 'Kapıda Ödeme'
                : props.row.odeme_yontemi == 'havale_eft'
                ? 'Banka Havalesi'
                : 'Kredi Kartı Ödeme'
            }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'tahsilat'">
          <b-badge
            :variant="props.row.tahsilat_durum == 1 ? 'success' : 'danger'"
            class="rounded-0 font-weight-light"
            style="font-size: 14px"
          >
            {{ props.row.tahsilat_durum == 1 ? 'Tahsil Edildi' : 'Tahsil Edilmedi' }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'durum'">
          <b-badge
            class="rounded-0 d-block font-weight-light"
            style="font-size: 14px"
            :style="{ background: props.row.siparis_drm_renk }"
          >
            {{ props.row.siparis_drm['tr'].baslik }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'fiyat'">
          <h5>
            <b-badge variant="success" class="rounded-0 font-weight-light d-block text-right">
              {{ props.row.sepet_toplam.genel_toplam | cur }} {{ props.row.kur_turu }}
            </b-badge>
          </h5>
          <h5 v-if="props.row.kur_turu != props.row.secilen_kur">
            <b-badge variant="info" class="rounded-0 font-weight-light d-block text-right">
              {{ props.row.kur_sepet_toplam.genel_toplam | cur }} {{ props.row.secilen_kur }}
            </b-badge>
          </h5>
        </span>

        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              class="rounded-0"
              variant="info"
              v-b-tooltip.hover.top="'Siparis Detay'"
              :to="{
                name: 'siparis-detay',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-eye" />
            </b-button>
            <b-button variant="danger" class="rounded-0" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <SiparisPagination
      :siparisler="siparisler"
      :count="count"
      :filter="filter"
      @handlerPageSizeChange="handlerPageSizeChange($event)"
      @handlerPageChange="handlerPageChange($event)"
    />
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { VueGoodTable } from 'vue-good-table';
import { ref, computed, defineComponent } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import SiparisPagination from './component/SiparisPagination.vue';
export default defineComponent({
  components: {
    VueGoodTable,
    SiparisPagination,
  },
  setup(_, context) {
    const expo = {};

    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.openAkModal = ref(false);
    expo.closeAkModal = ref(false);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.multiSelectRow = ref([]);
    expo.updateData = ref([]);
    expo.updateAkData = ref({});
    expo.filter = ref({
      arama: null,
      kategori_k_no: null,
      marka_k_no: null,
      siralama: null,
      statu: null,
      vitrin_gosterim: [],
      page: 0,
      pageSize: 20,
    });
    expo.count = ref(0);

    expo.columns = ref([
      {
        label: 'Siparis Numarası',
        field: 'k_no',
      },
      {
        label: 'Tarih',
        field: 'tarih',
      },
      {
        label: 'Uye',
        field: 'uye',
      },
      {
        label: 'Ödeme Tipi',
        field: 'odeme_tipi',
      },
      {
        label: 'Tahsilat',
        field: 'tahsilat',
      },
      {
        label: 'Durum',
        field: 'durum',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Fiyat',
        field: 'fiyat',
        width: '12%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
      },
    ]);

    expo.siparisler = ref([]);

    expo.handlerFetchAllData = async () => {
      context.emit('show', true);
      await store.dispatch('eTicSiparisListele', expo.filter.value).then((res) => {
        expo.siparisler.value = res.data.data;
        expo.count.value = res.data.count;
        context.emit('total', `${expo.count.value} kayıttan ${expo.siparisler.value.length} `);
        context.emit('show', false);
      });
    };
    expo.handlerFetchAllData();

    expo.selectionChanged = (event) => {
      expo.multiSelectRow.value = event.selectedRows;
    };

    expo.handlerTopluSil = () => {};

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('eTicSiparisSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      expo.handlerFetchAllData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.filter.value.page = expo.filter.value.page - 1;
      expo.handlerFetchAllData();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
